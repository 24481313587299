.header-main {
	position: fixed;
	top: 0;
	right: 0;
	margin: 0 auto;
	width: 100%;
	background: $color-white;
	z-index: 10;

	.sub-main-nav {
		position: relative;

		.svg-icon.border-jagged-bottom {
			position: absolute;
			bottom: -73px;
			color: $color-white;
			z-index: 1;

			@include mq(mobile) {
				bottom: -75px;
			}
		}
	}

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 15px 0 10px;

		@include mq(mobile) {
			padding: 5px;
		}
	}

	.sub-logos {
		align-items: center;
		display: flex;
		position: relative;
		z-index: 2;

		.icon-logo {
			margin: 0 10px 0 0;

			@include mq(mobile) {
				width: 155px;
				margin: 0;
			}
		}

		@include mq(tablet-down) {
			.icon-opening {
				display: none;
			}
		}


		.sub-seasonal-hours {
			display: inline-block;
			font-family: $font-alt;
			line-height: 1;
			text-align: center;
			text-transform: uppercase;
			width: 85px;

			@media only screen and (max-width: 1250px) {
				display: none;
			}
		}
	}

	ul.list-nav {
		@extend .list-default;

		font-family: $font-alt;

		@include mq(mobile) {
			max-height: 0;
			position: absolute;
			top: 55.42px;
			left: 0;
			width: 100%;
			background: $color-neutral;
			overflow: hidden;
			transition: all 0.3s ease;
			padding: 0 40px;
			z-index: 2;

			&.active {
				overflow: visible;
				max-height: 100vh;
				height: 100vh;
				padding: 50px 40px 40px;
			}
		}


		li.item-nav {
			display: inline-block;
			position: relative;

			@include mq(mobile) {
				display: block;
				font-size: $font-size-large;
				position: relative;

				&:after {
					content: '';
					width: 100%;
					height: 1px;
					background: $color-neutral-light;
					display: block;
					margin: 12px 0;
				}
			}


			&.active a {
				color: $color-secondary-pale;
			}

			a,
			.mod-dropdown {
				color: $color-secondary;
				cursor: pointer;

				&:hover {
					color: $color-secondary-pale;
				}

				&.btn {
					color: $color-white;
				}
			}

			&:not(:last-child) {
				margin: 0 30px 0 0;

				@include mq(tablet-down) {
					margin: 0 20px 0 0;
				}


				@include mq(mobile) {
					margin: 0;
				}
			}

			&.mod-login {
				margin: 0 0 0 15px;

				@include mq(mobile) {
					display: none;
				}
			}

			&.mod-sub-nav {
				@include mq(mobile) {
					&:before {
						content: '';
						border-style: solid;
						border-width: 2px 2px 0 0;
						content: '';
						display: inline-block;
						height: 8px;
						top: 6px;
						right: 2px;
						position: absolute;
						transform: rotate(135deg);
						vertical-align: top;
						width: 8px;
						border-color: $color-primary;
						transition: all 0.3s ease;
					}

					ul.sub-nav {
						background: $color-neutral;
						padding: 0;
						text-align: left;

						li.item-nav:last-child:after {
							content: none;
						}
					}

					&.mod-toggle {
						&:before {
							top: 8px;
							transform: rotate(-45deg);
						}

						ul.sub-nav {
							max-height: 300px;
							padding: 20px 0 0;

							li.item-nav {
								max-height: 300px;
							}
						}
					}

					ul.sub-nav {
						position: relative;
						width: 100%;
						position: relative;
						width: 100%;
						left: 0;
						top: 0;
					}
				}


				@include mq(tablet-up) {
					&:hover {
						ul.sub-nav {
							max-height: 300px;
							overflow: visible;
							box-shadow: 0 0px 14px 2px rgba($color-black, 0.2);
							padding: 20px 0 15px;
							overflow: visible;

							li.item-nav {
								max-height: 300px;
							}
						}

						color: $color-primary;
					}
				}
			}

			ul.sub-nav {
				@extend .list-default;

				position: absolute;
				top: 35px;
				left: -65px;
				right: 0;
				margin: 0 auto;
				text-align: center;
				min-width: 165px;
				max-height: 0;
				z-index: 99;
				background: $color-white;
				transition: all 0.5s ease;
				overflow: hidden;

				&:before {
					content: '';
					border-left: 7px solid transparent;
					border-right: 7px solid transparent;
					border-bottom: 7px solid $color-white;
					position: absolute;
					top: -7px;
				}

				li {
					display: block;
					max-height: 0;
					padding: 0 0 5px;
					transition: max-height 0.8s ease;
				}
			}
		}
	}

	.sub-mobile-buttons {
		display: none;
		align-items: center;

		@include mq(mobile) {
			display: flex;
		}


		.sub-opening-hours {
			display: inline-block;
			font-family: $font-alt;
			line-height: 1;
			text-align: center;
			text-transform: uppercase;
			width: 85px;
		}

		.btn-mobile {
			position: relative;
			display: none;
			z-index: 2;
			margin: 0 0 0 15px;
			flex-direction: column;
			justify-content: center;

			@include mq(mobile) {
				display: flex;
			}


			.sub-bar {
				background: $color-primary;
				display: block;
				width: 25px;
				height: 2px;
				margin: 0 0 6px;
				-webkit-transition: all 0.2s ease;
				transition: all 0.2s ease;

				&.bar-3 {
					margin: 0;
				}
			}

			&.active {
				.sub-bar {
					transform: translateY(3px) rotate(-45deg);
					background: $color-primary;
					margin: 0;

					&.bar-2 {
						transform: translateY(1px) rotate(45deg);
					}

					&.bar-3 {
						opacity: 0;
					}
				}
			}
		}
	}

	.sub-secondary-header {
		display: flex;
		justify-content: space-between;
		background: $color-secondary-dark;
		align-items: center;
		position: relative;

		@media only screen and (max-width: 1240px) {
			ul.list-nav {
				max-height: 0;
				position: absolute;
				top: 55.42px;
				left: 0;
				width: 100%;
				background: $color-neutral;
				overflow: hidden;
				transition: all 0.3s ease;
				padding: 0 40px;
				z-index: 2;

				&.active {
					overflow: visible;
					max-height: 100vh;
					height: 100vh;
					padding: 50px 40px 40px;
				}

				li.item-nav {
					display: block;
					font-size: $font-size-large;
					position: relative;

					&:after {
						content: '';
						width: 100%;
						height: 1px;
						background: $color-neutral-light;
						display: block;
						margin: 12px 0;
					}

					&:not(:last-child) {
						margin: 0;
					}
				}
			}
		}

		svg.border-jagged-top-bar {
			color: $color-secondary-dark;
			position: absolute;
			bottom: -77px;
			width: 100%;
			z-index: -1;
		}

		.sub-location {
			display: flex;
			align-items: center;

			@media only screen and (max-width: 1240px) {
				padding: 5px 0;
			}

			.svg-icon {
				margin: 0 20px 0 0;

				@media only screen and (max-width: 1240px) {
					margin: 0 15px 0 0;
				}
			}

			h4 {
				font-size: $font-size-h3;
				color: $color-white;
				font-family: $font-alt;
				margin: 0;

				a {
					color: $color-white;

					&:hover {
						color: $color-primary;
					}
				}

				@media only screen and (max-width: 1240px) {
					font-size: $font-size-large;
					position: relative;

					&.mod-mobile-toggle {
						display: flex;
						align-items: center;

						.btn-location-mobile-menu {
							position: relative;
							display: none;
							z-index: 1;
							margin: 0 0 0 15px;

							@media only screen and (max-width: 1240px) {
								display: block;
							}

							.sub-bar {
								background: $color-primary;
								display: block;
								width: 20px;
								height: 2px;
								margin: 0 0 6px;
								-webkit-transition: all 0.2s ease;
								transition: all 0.2s ease;

								&.bar-3 {
									margin: 0;
								}
							}
						}

						&.mod-active {
							.btn-location-mobile-menu .sub-bar {
								transform: translateY(0px) rotate(-45deg);
								background: $color-primary;
								margin: 0;

								&.bar-2 {
									transform: translateY(-2px) rotate(45deg);
								}

								&.bar-3 {
									opacity: 0;
								}
							}
						}
					}
				}
			}
		}

		ul.list-nav {
			@media only screen and (max-width: 1240px) {
				position: absolute;
				background: $color-secondary-medi-dark;
				top: 61px;
				z-index: 1;

				&.mod-active {
					padding: 30px 40px 40px;
					overflow: visible;
					max-height: 100vh;
					height: 100vh;
				}
			}

			@include mq(mobile) {
				top: 43px;
			}


			li.item-nav {
				font-size: $font-size-large;

				@media only screen and (max-width: 1240px) {
					font-size: $font-size-largish;

					&:after {
						background: #334F33;
					}
				}

				&:not(:last-child) {
					margin: 0 25px 0 0;

					@media only screen and (max-width: 1240px) {
						margin: 0;
					}
				}

				&:last-child {
					margin: 0;
				}

				&.active a,
				a:hover {
					color: $color-secondary-pale;
				}

				a {
					color: $color-secondary-light;
				}
			}
		}

		ul.list-social {
			@extend .list-default;

			display: flex;
			align-items: center;

			li {
				&:not(:last-child) {
					margin: 0 20px 0 0;
				}
			}
		}
	}
}

.sub-header-padding {
	height: 89.17px;

	@include mq(mobile) {
		height: 55.42px;
	}


	&.mod-secondary {
		height: 145.17px;

		@include mq(mobile) {
			height: 102.42px;
		}
	}
}
