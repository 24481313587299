#holding-page {
	background: $color-white;

	.section-content {
		align-items: center;
		background: url("../assets/images/holding-background.jpg") no-repeat;
		background-size: cover;
		display: flex;
		flex-direction: column;
		min-height: 850px;
		padding-top: 28px;

		@include mq(mobile) {
			background-image: url("../assets/images/leaves.png"), url("../assets/images/holding-background-mobile.jpg");
			background-position: left 100.1%, left top;
			background-repeat: no-repeat, no-repeat;
			background-size: 100%, cover;
			height: auto;
			padding: 15px 10px 85px;
		}


		.container {
			max-width: 950px !important;
			text-align: center;

			.svg-icon.icon-logo {
				width: auto;
			}

			h1 {
				font-family: $font-alt;
				font-size: $font-size-hero-med-largish;
				color: $color-white;
				margin-bottom: 0;
				margin-top: -20px;

				@include mq(mobile) {
					font-size: $font-size-hero-small;
					padding: 0 40px;
					margin-top: 0;
				}
			}

			h2 {
				color: $color-primary-light;
				font-family: $font-alt;
				font-size: $font-size-hero-med;
				font-weight: $font-weight-light;
				margin-bottom: 25px;
				text-transform: uppercase;

				@include mq(mobile) {
					font-size: $font-size-large;
					margin-top: 10px;
				}
			}

			.sub-content {
				padding: 0 50px;

				@include mq(mobile) {
					padding: 0;
				}


				p {
					color: $color-white;
					font-size: $font-size-larger;
					font-weight: $font-weight-thin;

					@include mq(mobile) {
						font-size: $font-size-large;
					}


					&:last-child {
						font-size: 18px;
						margin-bottom: 0;

						@include mq(mobile) {
							font-size: $font-size-med;
						}
					}
				}
			}

			.btn {
				font-size: $font-size-larger;
				max-width: 250px;
				margin-bottom: 25px;
				width: 100%;

				@include mq(mobile) {
					font-size: $font-size-large;
					max-width: none;
					padding: 7px 0;
				}
			}
		}
	}

	.section-party-cards {
		h2 {
			font-family: $font-alt;
			font-size: $font-size-largest;
			text-align: center;

			@include mq(mobile) {
				font-size: $font-size-large;
				margin-bottom: 0;
			}
		}

		ul.list-party-card {
			justify-content: left;
			padding-top: 100px;

			& > li {
				padding-bottom: 25px;

				@include mq(mobile) {
					width: calc(50% - 10px);
					margin: 0 5px 75px !important;
				}


				h4 {
					line-height: 34px;

					&.mod-coming-soon {
						line-height: 24px;

						span {
							font-size: $font-size-largish;
						}
					}
				}
			}
		}
	}

	footer {
		border-top: 1px solid $color-borders-light;
		display: flex;
		height: 123px;

		@include mq(mobile) {
			height: auto;
			padding: 30px 0 50px;
		}


		.container {
			align-self: center;
			display: flex;
			flex-direction: column;

			.sub-links {
				display: flex;
				flex-direction: row;
				margin-bottom: 5px;

				@include mq(mobile) {
					flex-direction: column;
					text-align: center;
				}


				.sub-mk-website {
					flex: 1;

					p {
						font-family: $font-alt;
						font-size: $font-size-largest;
						margin-bottom: 0;
					}
				}

				.sub-contact-btn {
					.btn {
						max-width: 250px;
						width: 100%;

						@include mq(mobile) {
							font-size: $font-size-large;
							max-width: none;
							padding: 7px 0;
							margin: 25px auto;
						}
					}
				}
			}

			.sub-copyright {
				p {
					color: $color-text-light;
					font-size: $font-size-smallish;
					margin-bottom: 0;

					@include mq(mobile) {
						text-align: center;
					}
				}
			}
		}
	}
}
