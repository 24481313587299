#news-post-landing,
#events-post-landing {

	.section-post-list {
		padding: 50px 0;

		@include mq(mobile) {
			padding: 25px 0;
		}


		h1 {
			margin: 0 auto 40px;
			text-align: center;
			font-size: $font-size-h2;
			font-family: $font-alt;

			@include mq(mobile) {
				margin: 0 0 25px;
				font-size: $font-size-largest;
			}
		}

		background: $color-neutral;
	}
}
