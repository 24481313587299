#location {
	.section-hero {
		background-size: cover;
		background-position: center;
		padding: 55px 0 65px;

		&.mod-mobile {
			display: none;
		}

		@include mq(mobile) {
			padding: 60px 0;
			display: none;

			&.mod-mobile {
				display: flex;
			}
		}


		.sub-copy {
			h1 {
				font-family: $font-alt;
				font-size: $font-size-hero-med;
				margin: 0;
				color: $color-white;
				line-height: 1.2;
				margin: 0 0 15px;

				@include mq(mobile) {
					font-size: $font-size-h3;
					margin: 0 0 8px;
				}


				span {
					display: block;
					font-size: $font-size-hero-larger;
					color: $color-secondary-light;

					@include mq(mobile) {
						font-size: $font-size-hero-med-largish;
					}
				}
			}

			h2 {
				color: $color-white;
				font-family: $font-alt;
				font-size: $font-size-hero-small;
				margin: 0 0 30px;

				@include mq(mobile) {
					font-size: $font-size-largish;
					max-width: 50%;
					margin: 0;
				}
			}

			.btn.btn-primary {
				padding: 14px 50px;

				@include mq(mobile) {
					margin: 20px 0 0;
					max-width: 50%;
				}
			}
		}
	}

	.section-tip {
		background: $color-neutral;
		padding: 30px 0;
		position: relative;

		@include mq(mobile) {
			padding: 20px 0;
		}


		svg {
			color: $color-neutral;
			position: absolute;
			width: 100%;
			text-align: center;
			z-index: 9;

			&.border-jagged-top {
				top: -73px;
				pointer-events: none;

				@include mq(mobile) {
					bottom: inherit;
					top: -74px;
				}
			}

			&.border-jagged-bottom {
				bottom: -82px;

				@include mq(mobile) {
					top: inherit;
					bottom: -77px;
				}
			}
		}

		.container {
			display: flex;
			flex-direction: column;
			align-items: center;

			h4 {
				font-size: $font-size-h1;
				font-family: $font-alt;
				color: $color-secondary;
				margin: 0 0 15px;

				@include mq(mobile) {
					font-size: $font-size-hero-small;

				}
			}

			.sub-tips {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;

				@include mq(mobile) {
					flex-direction: column;
				}

				.sub-tip {
					display: flex;
					max-width: 515px;
					width: 49%;
					justify-content: space-between;
					align-items: flex-start;

					@include mq(mobile) {
						max-width: none;
						flex-direction: column;
						text-align: center;
						width: 100%;
						margin: 0 0 30px;
					}

					.sub-copy {
						max-width: 295px;

						@include mq(tablet-down) {
							max-width: 265px;
						}

						@include mq(mobile) {
							max-width: none;
						}
					}

					h5 {
						margin: 0;
						font-size: $font-size-med;
						font-weight: $font-weight-bold;

						@include mq(mobile) {
							margin: 0 0 10px;
						}
					}
					p {
						font-size: $font-size-med;
						font-weight: $font-weight-regular;

						@include mq(mobile) {
							font-size: $font-size-standard;
						}


						&:last-of-type {
							margin: 0;

							@include mq(mobile) {
								margin: 0 0 15px;
							}
						}
					}
				}

				.btn {
					padding: 12px 30px;

					@include mq(mobile) {
						width: 100%;
						padding: 11px 0;
						text-align: center;
						font-size: $font-size-large;
					}
				}
			}
		}
	}

	.section-intro-boxes {
		ul.list-box {
			@extend .list-default;

			display: flex;

			@include mq(mobile) {
				flex-direction: column;
			}


			li {
				min-height: 180px;
				height: 12.847vw;
				flex-grow: 1;
				max-height: 250px;
				display: flex;
				align-items: center;
				text-align: center;
				position: relative;
				cursor: pointer;
				overflow: hidden;

				&:hover {
					h4 {
						margin: 0 0 5px;
					}

					.sub-text {
						max-height: 50px;
						opacity: 1;
					}

					.sub-overlay {
						background: rgba($color-secondary-dark, 0.9);
					}

					.sub-image {
						transform: scale(1.1);
					}
				}

				h4 {
					font-size: $font-size-h2;
					font-family: $font-alt;
					color: $color-white;
					margin: 0;
					transition: 0.4s all ease;

					@include mq(mobile) {
						font-size: $font-size-hero-small;
					}
				}

				.sub-text {
					max-height: 0;
					overflow: hidden;
					transition: 0.5s all ease;
					opacity: 0;

					@include mq(mobile) {
						max-width: 245px;
						margin: 0 auto;
					}
				}

				p {
					color: $color-primary;
					font-weight: $font-weight-semibold;

					@include mq(mobile) {
						display: none;
					}


					&:last-of-type {
						margin: 0;
					}
				}

				.sub-copy {
					max-width: 85%;
					margin: 0 auto;
					position: relative;
					z-index: 2;
				}

				.sub-overlay {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					background: rgba($color-black, 0.3);
					transition: 0.5s all ease;
				}

				.sub-image {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 0;
					background-size: cover;
					background-position: center;
					transition: 0.5s all ease;
				}
			}
		}
	}

	.section-admission {
		padding: 60px 0 80px;
		background-position: center;
		background-size: cover;
		position: relative;
		z-index: 3;

		@include mq(mobile) {
			padding: 60px 0 90px;

			.btn.btn-primary {
				padding: 11px 0;
				width: 100%;
				text-align: center;
				font-size: $font-size-large;
			}
		}


		header {
			max-width: 675px;
			text-align: center;
			margin: 0 auto 45px;

			@include mq(mobile) {
				max-width: none;
				margin: 0 0 35px;
			}


			h3 {
				font-size: $font-size-hero-med;
				color: $color-secondary;
				font-family: $font-alt;
				margin: 0 0 10px;

				@include mq(mobile) {
					font-size: $font-size-hero-small;
				}
			}

			h4 {
				font-size: $font-size-larger;
				margin: 0 0 25px;

				@include mq(mobile) {
					font-size: $font-size-largish;
				}
			}

			p {
				font-size: $font-size-largish;
				line-height: $line-height-med;

				@include mq(mobile) {
					font-size: $font-size-standard;
					padding: 0 5px;
				}


				&:last-of-type {
					margin: 0;
				}
			}
		}

		ul.list-admission-box {
			@extend .list-default;

			display: flex;
			justify-content: space-between;
			margin: 0 0 50px;

			@include mq(mobile) {
				margin: 0;
				flex-direction: column;
				justify-content: flex-start;
			}


			li {
				max-width: 285px;

				@include mq(mobile) {
					max-width: none;
					margin: 0 0 25px;
				}


				h4 {
					font-size: $font-size-h3;
					font-family: $font-alt;
					color: $color-secondary;
					margin: 0 0 20px;

					@include mq(mobile) {
						margin: 0 0 10px;
						font-size: $font-size-h4;
					}
				}

				p {
					font-weight: $font-weight-light;

					strong {
						font-weight: $font-weight-bold;
					}
				}
			}
		}

		.sub-more {
			text-align: center;

			h4 {
				font-weight: $font-weight-medium;
				font-size: $font-size-largest;
				margin: 0 0 35px;

				@include mq(mobile) {
					font-size: $font-size-largish;
					max-width: 225px;
					margin: 10px auto 25px;
				}
			}
		}
	}

	.section-party {
		height: auto;
		position: relative;
		background: $color-primary-pale;
		display: flex;
		align-items: center;
		padding: 50px 0;

		@include mq(mobile) {
			height: auto;
			flex-direction: column;
			padding: 0 0 60px;
		}


		svg.border-jagged-bottom {
			position: absolute;
			top: -75px;
			left: 0;
			width: 100%;
			color: $color-white;
			z-index: 2;
		}

		.sub-image {
			position: absolute;
			height: 100%;
			width: 50%;
			right: 0;
			top: 0;
			background-size: cover;
			background-position: left;

			@include mq(mobile) {
				position: relative;
				height: 300px;
				width: 100%;
				background-position: center;
				margin: 0 0 50px;
			}
		}

		.sub-copy {
			max-width: 405px;
			margin: 0 0 55px;

			h3 {
				color: $color-black;
				font-size: $font-size-h1;
				font-family: $font-alt;
				margin: 0 0 20px;

				@include mq(mobile) {
					font-size: $font-size-largest;
				}
			}

			p {
				font-size: $font-size-med;

				&:last-of-type {
					margin: 0;
				}

				strong {
					font-weight: $font-weight-bold;
				}
			}
		}

		.sub-buttons {
			max-width: 405px;
			display: flex;
			justify-content: space-between;

			@include mq(mobile) {
				flex-direction: column;
				justify-content: flex-start;
				max-width: none;
			}


			.btn {
				padding: 11px 0;
				width: 50%;
				max-width: 190px;
				text-align: center;

				@include mq(mobile) {
					width: 100%;
					max-width: none;
					font-size: $font-size-large;

					&:first-of-type {
						margin: 0 0 10px;
					}
				}
			}
		}
	}

	.section-food {
		height: 565px;
		position: relative;
		display: flex;
		align-items: center;

		@include mq(mobile) {
			height: auto;
			flex-direction: column;
			padding: 0 0 60px;
		}


		svg.border-jagged-top {
			position: absolute;
			bottom: -75px;
			width: 100%;
			color: $color-neutral;
			pointer-events: none;
		}

		.sub-background {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-size: cover;
			background-position: center;
			z-index: -1;

			@include mq(mobile) {
				position: relative;
				height: 300px;
				width: 100%;
				background-position: left;
				margin: 0 0 45px;
			}
		}

		.sub-copy {
			max-width: 565px;
			margin: 0 0 0 auto;

			h4 {
				font-size: $font-size-hero-med;
				font-family: $font-alt;
				margin: 0 0 20px;
				color: $color-white;

				@include mq(mobile) {
					color: $color-secondary;
					font-size: $font-size-largest;
					margin: 0 0 10px;
				}
			}

			p {
				color: $color-white;
				font-size: $font-size-med;
				font-weight: $font-weight-light;

				@include mq(mobile) {
					color: $color-text;
				}


				&:last-of-type {
					margin: 0;
				}
			}

			.sub-buttons {
				margin: 40px 0 0;
				max-width: 405px;
				display: flex;
				justify-content: space-between;

				@include mq(mobile) {
					flex-direction: column;
					justify-content: flex-start;
					max-width: none;
				}


				.btn {
					padding: 11px 0;
					width: 50%;
					max-width: 190px;
					text-align: center;

					@include mq(mobile) {
						width: 100%;
						max-width: none;
						font-size: $font-size-large;

						&:first-of-type {
							margin: 0 0 10px;
						}
					}
				}
			}
		}
	}

	.section-generic-boxes ul.list-box li:first-child {
		background: transparent;
	}

	.section-modal {
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		opacity: 0;
		transition: opacity 0.3s ease;

		.sub-close {
			position: absolute;
			top: 70px;
			right: 130px;
			z-index: 1;
			cursor: pointer;

			@include mq(mobile) {
				top: 15px;
				right: 35px;
			}


			&:before,
			&:after {
				position: absolute;
				left: 15px;
				content: ' ';
				height: 20px;
				width: 2px;
				background-color: $color-primary;
				transform: rotate(45deg);

				@include mq(mobile) {
					height: 25px;
				}
			}

			&:after {
				transform: rotate(-45deg);
			}
		}

		&.mod-active {
			opacity: 1;
			pointer-events: all;
		}

		.sub-overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
			background: rgba($color-black, 0.9);
		}

		.sub-content {
			max-width: 850px;
			margin: 0 auto;
			padding: 120px 0 0;

			@include mq(mobile) {
				padding: 40px 10px 0;
			}


			ul.list-modal-images {
				@extend .list-default;

				max-height: 435px;
				z-index: 0;

				li {
					margin: 0;
				}

				@include mq(mobile) {
					max-height: 56vw;
				}


				img {
					width: 100%;
					height: auto;
					display: block;
				}

				.slick-arrow {
					appearance: none;
					font-size: 0;
					border: none;
					background: none;
					outline: none;

					&:after {
						cursor: pointer;
						font-size: 14px;
						border-style: solid;
						border-width: 2px 2px 0 0;
						color: $color-primary;
						content: '';
						display: inline-block;
						height: 18px;
						left: -60px;
						position: absolute;
						top: 50%;
						transform: rotate(-135deg) translateY(-50%);
						vertical-align: top;
						width: 18px;
						z-index: 1;
					}

					&.slick-next:after {
						left: inherit;
						right: -60px;
						transform: rotate(45deg);
					}
				}
			}

			.sub-carousel-copy {
				background: $color-white;
				padding: 20px 0;
				text-align: center;
				position: relative;
				z-index: 1;

				h4 {
					font-family: $font-alt;
					font-size: $font-size-h2;
					margin: 0 0 10px;

					@include mq(mobile) {
						font-size: $font-size-larger;
					}
				}

				p {
					max-width: 620px;
					font-size: $font-size-med;
					line-height: $line-height-med;
					margin: 0 auto 20px;

					@include mq(mobile) {
						font-size: $font-size-small;
					}


					&:last-child {
						margin: 0 auto;
					}
				}
			}
		}
	}
}
