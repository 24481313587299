.section-party-cards {
	padding: 55px 0;
	position: relative;

	@include mq(mobile) {
		padding: 40px 0;
	}


	svg.border-jagged-top {
		color: $color-white;
		width: 100%;
		top: -75px;
		position: absolute;
	}

	h3 {
		font-size: $font-size-largest;
		font-family: $font-alt;
		margin: 0 0 100px;
		text-align: center;

		@include mq(mobile) {
			font-size: $font-size-largish;
		}
	}

	ul.list-party-card {
		@extend .list-default;

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;

		@include mq(mobile) {
			justify-content: space-between;
		}


		li {
			width: 25%;
			max-width: 260px;
			min-height: 210px;
			position: relative;
			margin: 0 0 95px;

			&::before {
				background-image: url("../assets/images/border-jagged-party.png");
				background-size: 100% 100%;
				content: '';
				height: 100%;
				position: absolute;
				left: 0;
				width: 100%;
				z-index: 0;
			}

			@include mq(mobile) {
				width: calc(50% - 5px);
				max-width: none;
				min-height: 0;
				margin: 0 0 75px !important;
			}


			&:not(:nth-child(4n)) {
				margin: 0 13px 95px 0;
			}

			a.sub-link {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
			}

			.sub-image {
				margin: -70px 0 15px;
				height: 185px;
				position: relative;

				@include mq(mobile) {
					margin: -60px 0 15px;
					height: auto;
				}


				img {
					display: block;
					margin: 0 auto;

					@include mq(mobile) {
						width: 90%;
						height: auto;
					}
				}
			}

			h4 {
				font-size: $font-size-h2;
				font-family: $font-alt;
				color: $color-white;
				margin: 0;
				line-height: $line-height-reset;

				@include mq(mobile) {
					font-size: $font-size-larger;
					margin: 0 0 20px;
				}
			}
		}
	}
}
