ul.list-posts {
	@extend .list-default;

	display: inline-flex;
	justify-content: space-between;
	flex-flow: row wrap;
	width: 100%;

	@include mq(mobile) {
		flex-flow: column;
	}


	li.item-post {
		width: calc(50% - 17.5px);
		margin: 0 0 30px;
		background: $color-white;
		position: relative;
		cursor: pointer;
		transition: box-shadow 0.2s ease;

		&::before {
			background-image: url("../assets/images/border-jagged-news.png");
			background-size: 100% 100%;
			content: "";
			position: absolute;
			bottom: -7px;
			left: -1px;
			height: 8px;
			width: 100.2%;
		}

		@include mq(mobile) {
			width: 100%;
			margin: 0 auto 15px;
			max-width: 90%;
		}


		a {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			display: block;
		}

		img {
			width: 100%;
			height: auto;
			display: block;
		}

		&:hover {
			box-shadow: 0 0 30px 0 rgba($color-black, 0.15);

			.sub-detail {
				h3 {
					color: $color-primary;
				}
			}
		}

		.sub-detail {
			padding: 25px 0;
			position: relative;
			text-align: center;
			max-width: 300px;
			margin: 0 auto;

			@include mq(mobile) {
				padding: 35px 20px;
			}


			h3,h4 {
				font-size: $font-size-largest;
				font-family: $font-alt;
				margin: 0 0 10px;
				color: $color-link;
				transition: color 0.2s ease;
				line-height: 1.2;

				@include mq(mobile) {
					font-size: $font-size-large;
				}
			}

			.sub-date {
				margin: 0 0 10px;
				font-size: $font-size-tiny;
				font-weight: $font-weight-semibold;
				text-transform: uppercase;

			}

			ul.list-details {
				@extend .list-default;
				display: flex;
				justify-content: center;
				align-items: center;

				li {
					font-size: $font-size-tiny;
					font-weight: $font-weight-semibold;
					text-transform: uppercase;

					&:first-child:after {
						content: '';
						display: inline-block;
						margin: 0 10px;
						width: 1px;
						height: 8.5px;
						background: $color-text-light;
					}
				}
			}
		}
	}
}
