#grown-up-stuff,
#grown-up-stuff-page {
	.section-hero {
		background-size: cover;
		background-position: right;
		height: 280px;
		display: flex;
		align-items: center;

		@extend %hero-jagged-edge;

		&.mod-mobile {
			display: none;
		}

		@include mq(mobile) {
			background-position: center;
			height: 240px;
			display: none;

			&.mod-mobile {
				display: flex;
			}
		}

		.sub-copy {
			max-width: 610px;

			@include mq(mobile) {
				text-align: center;
				max-width: none;
			}

			h1 {
				font-family: $font-alt;
				font-size: $font-size-hero-med-large;
				line-height: $line-height-reset;
				color: $color-white;
				margin: 0 0 10px;

				@include mq(mobile) {
					font-size: $font-size-h1;
				}
			}

			h3 {
				font-size: $font-size-larger;
				color: $color-white;
				font-family: $font-alt;
				margin: 0;

				@include mq(mobile) {
					font-size: $font-size-largish;
				}
			}
		}
	}

	.section-content {
		margin: 65px 0 150px;

		@include mq(mobile) {
			margin: 35px 0 0;
		}

		.container {
			display: flex;
			justify-content: space-between;

			@include mq(mobile) {
				flex-direction: column;
			}
		}

		.sub-aside {
			max-width: 270px;
			flex-grow: 1;

			@include mq(mobile) {
				margin: 0 0 5px;
				max-width: none;
			}

			ul.list-sub-page {
				@extend .list-default;

				max-width: 235px;
				width: 100%;

				@include mq(mobile) {
					overflow: hidden;
					max-width: none;
					max-height: 41px;
					transition: all 0.3s ease;
				}

				&.mod-active {
					max-height: 1000px;

					li {
						max-height: 41px;

						&.mod-mobile:before {
							top: 6px;
							transform: rotate(-45deg);
						}
					}
				}

				li {
					font-size: $font-size-largish;
					font-family: $font-alt;

					@include mq(mobile) {
						max-height: 0;
						transition: all 0.3s ease;

						&:last-child {
							margin: 0 0 40px;
						}
					}

					&.mod-mobile {
						display: none;
						color: $color-primary;
						position: relative;
						max-height: 41px;

						&:before {
							content: "";
							border-style: solid;
							border-width: 2px 2px 0 0;
							content: "";
							display: inline-block;
							height: 8px;
							top: 4px;
							right: 2px;
							position: absolute;
							transform: rotate(135deg);
							vertical-align: top;
							width: 8px;
							border-color: $color-primary;
							transition: all 0.3s ease;
						}

						&:after {
							background: transparent;
						}

						@include mq(mobile) {
							display: block;
						}
					}

					&:after {
						content: "";
						width: 100%;
						height: 1px;
						display: block;
						background: $color-borders-light;
						margin: 13px 0;
					}
				}
			}

			img {
				width: 100%;
				height: auto;
				display: block;

				@include mq(mobile) {
					display: none;
				}
			}
		}

		.sub-copy {
			max-width: 670px;
			width: 100%;

			@include mq(mobile) {
				max-width: none;
			}

			h2 {
				font-size: $font-size-h1;
				font-family: $font-alt;

				@include mq(mobile) {
					font-size: $font-size-largest;
					margin: 0 0 15px;
				}
			}

			.btn.btn-primary.intro-btn {
				margin-bottom: 40px;
			}

			p {
				font-size: $font-size-med;
				font-weight: $font-weight-light;
				line-height: $line-height-medi-large;
				margin: 0 0 40px;

				@include mq(mobile) {
					font-size: $font-size-standard;
					margin: 0 0 25px;
				}
			}

			.btn.btn-primary {
				margin: 10px 0 0;
			}

			table {
				tbody {
					display: flex;
					flex-direction: column;
				}

				tr {
					display: flex;
					height: auto !important;

					td {
						border: 1px solid $color-borders-light;
						padding: 5px;

						p {
							margin: 0;
							line-height: $line-height-reset;
						}
					}
				}
			}
		}

		.sub-mobile-image {
			display: none;

			@include mq(mobile) {
				display: block;

				img {
					margin: 70px 0 0;
				}
			}

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}
}

#grown-up-stuff-page {
	.section-content .sub-aside {
		ul.list-sub-page {
			display: none;

			@include mq(mobile) {
				display: block;
			}
		}
	}
}
