.section-generic-boxes {
	position: relative;

	svg.border-jagged-bottom {
		color: $color-neutral;
		position: absolute;
		top: -75px;
		width: 100%;
		z-index: 2;
	}

	ul.list-box {
		@extend .list-default;

		li {
			display: flex;
			align-items: center;
			height: 565px;
			position: relative;
			background: $color-neutral;

			@include mq(mobile) {
				height: auto;
				flex-direction: column;
				padding: 0 0 60px;
			}


			&:nth-child(2n) {
				background: $color-primary-pale;

				.sub-copy {
					margin: 0 auto 0 0;

					h4 {
						color: $color-black;
					}
				}

				.sub-image {
					left: inherit;
					right: 0;
				}
			}

			.sub-image {
				position: absolute;
				top: 0;
				left: 0;
				width: 50%;
				height: 100%;
				background-size: cover;
				background-position: right;

				@include mq(mobile) {
					width: 100%;
					height: 290px;
					margin: 0 0 50px;
					position: relative;
					background-position: center;
				}
			}

			.sub-copy {
				max-width: 415px;
				margin: 0 0 0 auto;

				h4 {
					font-size: $font-size-h1;
					font-family: $font-alt;
					margin: 0 0 20px;

					@include mq(mobile) {
						font-size: $font-size-largest;
					}
				}

				p {
					font-size: $font-size-med;
					line-height: $line-height-med;

					&:last-of-type {
						margin: 0;
					}

					strong {
						font-weight: $font-weight-bold;
					}
				}

				a[href^="tel:"] {
					color: $color-text;
				}
			}

			.sub-buttons {
				margin: 40px 0 0;
				max-width: 405px;
				display: flex;
				justify-content: space-between;

				@include mq(mobile) {
					flex-direction: column;
					justify-content: flex-start;
					max-width: none;
				}


				.btn {
					padding: 11px 0;
					width: 50%;
					max-width: 190px;
					text-align: center;

					@include mq(mobile) {
						width: 100%;
						max-width: none;
						font-size: $font-size-large;

						&:first-of-type {
							margin: 0 0 10px;
						}
					}
				}
			}
		}
	}
}
