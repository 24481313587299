#home {
	.section-hero {
		background-size: cover;
		background-position: center;
		text-align: center;
		padding: 80px 0 155px;

		&.mod-mobile {
			display: none;
		}

		@extend %hero-jagged-edge;

		@include mq(mobile) {
			padding: 80px 0 100px;
			display: none;

			&.mod-mobile {
				display: flex;
			}
		}


		.sub-copy {
			max-width: 765px;
			margin: 0 auto;
		}

		h1, h2 {
			font-family: $font-alt;
			color: $color-white;
			font-size: $font-size-hero-large;
			margin: 0;

			@include mq(mobile) {
				font-size: $font-size-h1;
				margin: 0 0 10px;
			}
		}

		h2 {
			font-size: $font-size-larger;
			margin: 0 auto 30px;
			max-width: 670px;

			@include mq(mobile) {
				font-size: $font-size-largish;
				max-width: 95%;
			}
		}

		h3 {
			font-size: $font-size-med;
			color: $color-primary-light;
			font-weight: $font-weight-bold;
			margin: 0;
			letter-spacing: 0.5px;

			@include mq(mobile) {
				font-size: $font-size-small;
			}
		}
	}

	.section-locations {
		max-width: 940px !important;
		margin: -100px auto 100px;

		@include mq(mobile) {
			max-width: 100% !important;
			margin: -10px auto 0;
		}


		ul.list-locations {
			@extend .list-default;

			display: flex;
			justify-content: space-between;

			@include mq(mobile) {
				flex-wrap: wrap;
				justify-content: flex-start;
				align-items: center;
			}


			li {
				max-width: 445px;
				text-align: center;
				padding: 0 50px 40px;
				background: $color-neutral;
				position: relative;

				@include mq(mobile) {
					max-width: 90%;
				}

				&::before {
					background-image: url("../assets/images/border-jagged-locations.png");
					background-size: 100% 100%;
					content: "";
					height: 105%;
					position: absolute;
					left: -2%;
					width: 104%;
					z-index: 0;
					top: -3%;

					@include mq(mobile) {
						left: -1.5%;
						width: 103%;
					}
				}

				@include mq(mobile) {
					padding: 0 25px 25px;
					width: 100%;
					margin: 0 auto 75px;
				}


				.sub-image {
					margin: -62px 0 20px;
					position: relative;

					img {
						display: block;

						@include mq(mobile) {
							width: 100%;
							height: auto;
						}
					}
				}

				p {
					font-size: $font-size-med;
					font-weight: $font-weight-light;

					@include mq(mobile) {
						display: none;
					}
				}

				.btn {
					padding: 14px 105px;
					font-size: $font-size-h3;
					position: relative;

					@include mq(mobile) {
						width: 100%;
						padding: 10px 0;
						text-align: center;
						font-size: $font-size-large;
					}
				}
			}
		}
	}

	.section-news {
		padding: 65px 0 150px;
		text-align: center;
		background-position: center;
		background-size: cover;
		position: relative;

		svg {
			position: absolute;
			left: 0;
			width: 100%;

			&.border-jagged-bottom {
				top: -75px;
				color: $color-white !important;
			}

			&.border-jagged-top {
				color: $color-neutral;
				bottom: -75px;
			}
		}

		@include mq(mobile) {
			padding: 60px 0 80px;
		}


		h3 {
			font-size: $font-size-h1;
			font-family: $font-alt;
			color: $color-white;
			margin: 0 0 60px;

			@include mq(mobile) {
				font-size: $font-size-largest;
				margin: 0 0 45px;
			}
		}
	}
}
