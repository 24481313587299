#classes-clubs {

	.section-hero {
		background-size: cover;
		background-position: right;
		height: 280px;
		display: flex;
		align-items: center;

		@extend %hero-jagged-edge;

		&.mod-mobile {
			display: none;
		}

		@include mq(mobile) {
			height: 240px;
			background-position: center;
			display: none;

			&.mod-mobile {
				display: flex;
			}
		}

		.sub-copy {
			max-width: 610px;

			@include mq(mobile) {
				text-align: center;
				margin: 0 auto;
				max-width: 310px;
			}

			h1 {
				font-family: $font-alt;
				font-size: $font-size-hero-med-large;
				color: $color-white;
				margin: 0 0 10px;

				@include mq(mobile) {
					font-size: $font-size-h1;
				}
			}

			h2 {
				font-size: $font-size-larger;
				color: $color-white;
				font-family: $font-alt;
				margin: 0;

				@include mq(mobile) {
					font-size: $font-size-largish;
				}
			}
		}
	}

	.section-classes {
		margin: 80px 0 150px;

		@include mq(mobile) {
			margin: 40px 0 0;
		}

		ul.list-class {
			@extend .list-default;

			li {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 0 0 100px;

				@include mq(mobile) {
					margin: 0 0 80px;
					flex-direction: column;
					justify-content: flex-start;
				}

				.sub-image {
					max-width: 420px;
					position: relative;

					&::before {
						background-image: url("../assets/images/border-jagged-wide.png");
						background-size: 100% 100%;
						content: "";
						height: 100%;
						position: absolute;
						left: 0;
						width: 100.6%;
						z-index: 0;
						top: 0;
					}

					@include mq(mobile) {
						max-width: none;
						margin: 0 0 20px;
					}

					img {
						display: block;
						width: 100%;
						height: auto;
					}
				}

				.sub-copy {
					max-width: 575px;
					width: 100%;

					@include mq(mobile) {
						max-width: none;
					}

					h4 {
						font-size: $font-size-h3;
						font-family: $font-alt;
						margin: 0 0 20px;

						@include mq(mobile) {
							font-size: $font-size-large;
							margin: 0 0 10px;
						}

					}

					p {
						font-size: $font-size-small;
						font-weight: $font-weight-light;
						line-height: $line-height-med;
					}

					.sub-intro {

						p {
							font-size: $font-size-largish;
							line-height: $line-height-base;
							margin: 0 0 20px;

							@include mq(mobile) {
								font-size: $font-size-standard;
							}
						}
					}

					a.btn.btn-primary {
						margin: 20px 0 0;
						padding: 13px 40px;

						@include mq(mobile) {
							font-size: $font-size-large;
							padding: 11px 0;
							width: 100%;
							text-align: center;

						}
					}
				}
			}
		}
	}
}