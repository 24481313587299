.svg-icon {
	color: #ff99f8; // Default colour to stand out
	display: inline-block;
	vertical-align: middle;

	&.icon-logo {
		width: 236px;

		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}

	&.icon-opening {
		height: 55px;
		width: 180px;
	}

	&.icon-at {
		height: 18px;
		width: 18px;
		color: $color-link;
	}

	&.icon-phone {
		height: 18px;
		width: 18px;
		color: $color-link;
	}

	&.icon-wifi {
		height: 46px;
		width: 68px;
	}

	&.icon-location {
		height: 24px;
		width: 24px;
		color: $color-secondary-light;
	}

	&.icon-login {
		height: 20px;
		width: 20px;
		color: $color-primary;
	}

	// Social

	&.icon-social {
		height: 25px;
		width: 24px;
		color: $color-secondary-light;
	}

	&.icon-twitter {
		height: 20px;
		width: 28px;
		color: $color-secondary-light;

		&:hover {
			color: $color-primary;
		}
	}

	&.icon-linkedin {
		height: 23px;
		width: 24px;
		color: $color-secondary-light;

		&:hover {
			color: $color-primary;
		}
	}

	&.icon-linkedin-alt {
		height: 24px;
		width: 24px;
		color: $color-secondary-light;

		&:hover {
			color: $color-primary;
		}
	}

	&.icon-facebook {
		height: 26px;
		width: 13px;
		color: $color-secondary-light;

		&:hover {
			color: $color-primary;
		}
	}

	&.icon-pinterest {
		height: 27px;
		width: 21px;
		color: $color-secondary-light;

		&:hover {
			color: $color-primary;
		}
	}

	&.icon-instagram {
		height: 24.5px;
		width: 25.5px;
		color: $color-secondary-light;

		&:hover {
			color: $color-primary;
		}
	}

	// Borders

	&.border-jagged-bottom {
		width: 100%;
		height: 150px;
		pointer-events: none;
	}
}
