#careers {
	.section-hero {
		display: flex;
		align-items: center;
		height: 280px;

		@extend %hero-jagged-edge;

		&.mod-mobile {
			display: none;
		}

		@include mq(mobile) {
			background-position: center;
			height: 240px;
			align-content: center;
			display: none;

			&.mod-mobile {
				display: flex;
			}
		}


		.sub-copy {
			max-width: 595px;
			margin: 0 auto;
			text-align: center;

			@include mq(mobile) {
				text-align: center;
				max-width: none;
			}


			h1 {
				font-family: $font-alt;
				font-size: $font-size-hero-med-large;
				color: $color-white;
				margin: 0 0 10px;

				@include mq(mobile) {
					font-size: $font-size-h1;
				}
			}

			h3 {
				font-size: $font-size-larger;
				color: $color-white;
				font-family: $font-alt;
				margin: 0;

				@include mq(mobile) {
					font-size: $font-size-largish;
				}
			}
		}

		.btn.btn-primary {
			font-size: $font-size-largest;
			position: absolute;
			bottom: -23px;
			left: 0;
			right: 0;
			text-align: center;
			max-width: 250px;
			margin: 0 auto;

			@include mq(mobile) {
				font-size: $font-size-large;
				padding: 11px 0;
				max-width: 215px;
				bottom: -21.5px;
			}
		}
	}

	.section-boxes {
		padding: 80px 0 85px;
		position: relative;

		@include mq(mobile) {
			padding: 65px 0 20px;
		}


		svg.border-jagged-top {
			width: 100%;
			color: $color-neutral;
			position: absolute;
			bottom: -75px;
		}

		.container {
			max-width: 1110px !important;
		}

		ul.list-box {
			@extend .list-default;

			display: flex;
			justify-content: space-between;

			@include mq(mobile) {
				flex-direction: column;
			}


			> li {
				max-width: 510px;

				@include mq(mobile) {
					max-width: none;
					margin: 0 0 50px;
				}


				.sub-image {
					margin: 0 0 30px;
					position: relative;

					&:before {
						background-image: url("../assets/images/border-jagged-wide.png");
						background-size: 100% 100%;
						content: "";
						height: 100%;
						position: absolute;
						left: 0;
						width: 100.6%;
						z-index: 0;
						top: 0;
					}

					@include mq(mobile) {
						margin: 0 0 20px;
					}


					img {
						display: block;
						width: 100%;
						height: auto;
					}
				}

				.sub-copy {
					h4 {
						font-family: $font-alt;
						font-size: $font-size-hero-small;
						margin: 0 0 15px;

						@include mq(mobile) {
							font-size: $font-size-large;
							margin: 0 0 10px;
						}
					}

					p, ul li, ol li {
						font-size: $font-size-med;
						line-height: $line-height-med;
						font-weight: $font-weight-light;

						@include mq(mobile) {
							font-size: $font-size-standard;
						}
					}

					ul, ol {
						padding: 0;
						margin: 0 0 20px 14px;
					}
				}
			}
		}
	}

	.section-vacancies {
		padding: 95px 0 160px;
		position: relative;

		@include mq(mobile) {
			padding: 75px 0 60px;
		}


		svg.border-jagged-footer {
			width: 100%;
			position: absolute;
			top: -87px;

			@include mq(mobile) {
				top: -78px;
			}
		}

		.sub-header {
			max-width: 870px;
			margin: 0 0 50px;

			@include mq(mobile) {
				max-width: none;
				margin: 0 0 45px;
			}


			h2 {
				font-family: $font-alt;
				font-size: $font-size-h1;
				margin: 0 0 10px;

				@include mq(mobile) {
					font-size: $font-size-largest;
				}
			}

			p {
				font-size: $font-size-large;
				font-weight: $font-weight-light;

				@include mq(mobile) {
					font-size: $font-size-largish;
				}


				&:last-of-type {
					margin: 0;
				}
			}
		}

		ul.list-vacancy {
			@extend .list-default;

			max-width: 850px;

			@include mq(mobile) {
				max-width: none;
			}


			li.item-vacancy {
				&:after {
					content: '';
					width: 100%;
					height: 2px;
					display: block;
					background: $color-borders-light;
					margin: 40px 0;
				}

				h4 {
					font-family: $font-alt;
					font-size: $font-size-large;
					margin: 0 0 15px;

					@include mq(mobile) {
						font-size: $font-size-largish;
						margin: 0 0 10px;
					}
				}

				h5 {
					font-size: $font-size-standard;
					font-weight: $font-weight-medium;
					margin: 0 0 15px;

					@include mq(mobile) {
						font-size: $font-size-med;
						margin: 0 0 10px;
					}
				}

				pzq {
					font-size: $font-size-med;
					line-height: $line-height-medi-large;
					color: $color-text-lightish;

					&:last-of-type {
						margin: 0;
					}
				}

				ul li {
					font-size: $font-size-med;
					color: $color-text-lightish;
					margin: 0 0 10px;
				}
			}
		}
	}
}
