form {
	.sub-errors p {
		margin: 0 0 5px;
		font-size: $font-size-small;
		color: $color-validation-error;
	}

	.form-element {
		position: relative;
		margin: 30px 0;

		&.error {
			label, select {
				color: $color-validation-error !important;
			}
		}

		input[type=text] {
			width: 100%;
			border: none;
			border-bottom: 2px solid $color-borders-light;
			transition: 0.2s ease;
			padding: 0 10px 0 150px;
			font-size: $font-size-large;
			color: $color-text;
			min-height: 50px;
			outline: none;

			@include mq(mobile) {
				font-size: $font-size-med;
			}


			&:focus {
				border-color: $color-primary;

				+ label {
					color: $color-primary;
					font-size: $font-size-med;
					font-weight: $font-weight-semibold;
				}

				@include mq(mobile) {
					@include placeholder {
						color: $color-primary;
					}
				}
			}

			@include placeholder {
				color: $color-white;
			}


			@include mq(mobile) {
				padding: 0 10px;
				font-size: $font-size-small;

				@include placeholder {
					color: $color-text;
				}
			}
		}

		label {
			position: absolute;
			bottom: 15px;
			left: 0;
			width: auto;
			display: block;
			transition: 0.2s ease;

			@include mq(mobile) {
				display: none;
			}
		}

		textarea {
			min-height: 50px;
		}

		&.mod-select {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				top: calc(50% - 5px);
				right: 25px;
				transform: translateY(-50%) rotate(135deg);
				border-style: solid;
				border-width: 1px 1px 0 0;
				display: inline-block;
				height: 8.5px;
				border-color: $color-primary;
				vertical-align: top;
				width: 8.5px;
			}

			select {
				width: 100%;
				border: none;
				border-radius: 0;
				-webkit-appearance: none;
				background: transparent;
				border-bottom: 2px solid $color-borders-light;
				transition: 0.2s ease;
				padding: 0 150px 0 0;
				outline: none !important;
				font-size: $font-size-med;
				color: $color-text;
				min-height: 50px;

				&:focus {
					border-color: $color-primary;
				}
			}
		}
	}

	.btn.btn-primary, .footer-main button {
		margin: 25px 0 0;
		padding: 13px 70px;
		font-family: $font-alt;

		@include mq(mobile) {
			padding: 15px 0;
			width: 100%;
			margin: 55px 0 0;
		}
	}
}

input, select, textarea, button, optgroup {
	font-family: $font-standard !important;
}

input[type='submit'] {
	font-family: $font-alt !important;
}
