// IE10 & IE11 specific styling
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {


	.header-main .sub-secondary-header svg.border-jagged-top-bar{
		bottom: -44px !important;
	}

	#home,
	#parties,
	#party-page {
		.section-hero svg.border-jagged-top {
			bottom: -288px;
		}

		.section-news svg.border-jagged-top {
			bottom: -391px !important;
		}
	}

	#contact {
		.section-hero svg.border-jagged-top {
			bottom: -174px;
		}
	}

	#about,
	#careers,
	#classes-clubs,
	#grown-up-stuff,
	#grown-up-stuff-page {
		.section-hero svg.border-jagged-top {
			bottom: -138px;
		}
	}

	#about .section-team svg.border-jagged-top {
		top: -751px !important;
	}

	#careers  {

		.section-boxes svg.border-jagged-top {
			bottom: -518px !important;
		}

		.section-hero .btn-primary.btn {
			width: 250px;
		}

		.section-vacancies svg.border-jagged-footer {
			top: -2248px !important;
		}
	}

	.footer-main svg.border-jagged-footer {
			top: -144px !important;
	}

	#contact .section-map .sub-locations {
		width: 780px;
	}

	#location .section-tip svg.border-jagged-top {
		bottom: 37px !important;
	}

	#events-post,
	#news-post {

		.section-related.post-page svg.border-jagged-top {
			top: -342px !important;
		}
	}
 }