.section-testimonials {
	padding: 65px 0 85px;
	position: relative;
	background: $color-neutral;

	@include mq(mobile) {
		padding: 70px 0 50px;
	}

	.sub-testimonials {
		max-width: 710px;

		@include mq(mobile) {
			max-width: none;
		}

		h4 {
			font-size: $font-size-h1;
			font-family: $font-alt;
			margin: 0 0 25px;

			@include mq(mobile) {
				font-size: $font-size-largest;
			}
		}

		ul.list-testimonial {
			@extend .list-default;

			li.item-testimonial {
				margin: 0;

				p {
					font-size: $font-size-large;
					font-weight: $font-weight-light;
					font-style: italic;
					line-height: $line-height-medi-large;

					@include mq(mobile) {
						font-size: $font-size-largish;
						line-height: $line-height-large;

						&:last-child {
							margin: 0 0 80px;
						}
					}
				}

				h5 {
					font-size: $font-size-med;
					font-weight: $font-weight-light;
					color: $color-secondary;
					margin: 0;

					@include mq(mobile) {
						font-size: $font-size-small;
						margin: 0 0 35px;
					}
				}
			}

			ul.slick-dots {
				position: absolute;
				right: 0;
				bottom: 6px;
				justify-content: flex-end;

				@include mq(mobile) {
					position: relative;
					right: inherit;
					bottom: 0;
					justify-content: flex-start;
				}
			}
		}
	}

	.sub-image {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		z-index: 9;

		@include mq(mobile) {
			top: inherit;
			bottom: -35px;
			transform: none !important;
			max-width: 170px;

		}

		&.mod-staff {
			transform: translateY(-58%);
		}

		img  {
			display: block;

			@include mq(mobile) {
				width: 100%;
				height: auto;
			}
		}
	}
}