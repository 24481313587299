#parties {

	.section-hero {
		background-size: cover;
		background-position: center;
		height: 400px;
		display: flex;

		@extend %hero-jagged-edge;

		@include mq(mobile) {
			background-position: center;
			height: auto;
			padding: 55px 0;
		}

		.sub-copy {
			max-width: 610px;
			margin: 65px auto 0;
			text-align: center;

			@include mq(mobile) {
				max-width: 315px;
				margin: 0 auto;

				top: -25px;
    			position: relative;

			}

			h1 {
				font-family: $font-alt;
				font-size: $font-size-hero-med-large;
				color: $color-white;
				margin: 0 0 10px;

				@include mq(mobile) {
					font-size: $font-size-h1;

				}
			}

			h2 {
				font-size: $font-size-larger;
				color: $color-white;
				font-family: $font-alt;
				margin: 0;

				@include mq(mobile) {
					font-size: $font-size-largish;
				}
			}
		}
	}

	.section-generic-boxes svg.border-jagged-bottom {
		color: $color-white;
	}
}