// Clearfix
.clearfix {
	zoom: 1;

	&:after {
		clear: both;
	}

	&:before,
	&:after {
		content: "";
		display: table;
	}
}

// Remove selectability
.noselect {
	-webkit-touch-callout: none;
	user-select: none;
}

// Remove focus styles
.nofocus {
	&:focus {
		outline-style: none;
		box-shadow: none;
	}
}

// Default list styles
.list-default {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
}

@function highlight-by($color, $percent) {
	@if lightness($color) > 50% {
		@return darken($color, $percent);
	}
	@else {
		@return lighten($color, $percent);
	}
}

// Mixin for placeholder colour change
@mixin placeholder {
	&::-webkit-input-placeholder { @content; }
	&:-moz-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; }
}



// Split absoulted child items left and right but a certain percentage
@mixin splitLeftRight($listItems: 5, $percentIncrease: 30) {

	$defaultLeftRight: $percentIncrease;

	@for $i from 0 through $listItems {

		// Make sure not the first element
		@if $i != 1 {
			// If even
			@if $i % 2 == 0 {
				&:nth-child(#{$i}) {
					right: #{$defaultLeftRight + '%'};
				}
			}
			// Else odd
			@else {
				&:nth-child(#{$i}) {
					left: #{$defaultLeftRight + '%'};
					$defaultLeftRight: $defaultLeftRight + $percentIncrease;
				}
			}
		}
	}
}