#about {
	.section-hero {
		background-size: cover;
		background-position: right;
		height: 280px;
		display: flex;
		align-items: center;

		@extend %hero-jagged-edge;

		&.mod-mobile {
			display: none;
		}

		@include mq(mobile) {
			background-position: center;
			height: 240px;
			display: none;

			&.mod-mobile {
				display: flex;
			}
		}


		.sub-copy {
			max-width: 610px;

			@include mq(mobile) {
				text-align: center;
				max-width: none;
			}


			h1 {
				font-family: $font-alt;
				font-size: $font-size-hero-med-large;
				color: $color-white;
				margin: 0 0 10px;

				@include mq(mobile) {
					font-size: $font-size-h1;
				}
			}

			h3 {
				font-size: $font-size-larger;
				color: $color-white;
				font-family: $font-alt;
				margin: 0;

				@include mq(mobile) {
					font-size: $font-size-largish;
				}
			}
		}
	}

	.section-intro {
		margin: 50px 0 110px;

		@include mq(mobile) {
			margin: 35px 0;
		}


		.sub-intro {
			max-width: 870px;
			margin: 0 0 50px;

			@include mq(mobile) {
				max-width: none;
				text-align: center;
			}


			p {
				font-size: $font-size-large;
				font-weight: $font-weight-light;
				color: $color-secondary;

				@include mq(mobile) {
					font-size: $font-size-largish;
				}


				&:last-of-type {
					margin: 0;
				}
			}
		}

		ul.list-box {
			@extend .list-default;

			display: flex;
			justify-content: space-between;

			@include mq(mobile) {
				flex-direction: column;
			}


			li {
				max-width: 510px;

				@include mq(mobile) {
					max-width: none;
					margin: 0 0 50px;
				}


				.sub-image {
					margin: 0 0 30px;
					position: relative;

					&:before {
						background-image: url("../assets/images/border-jagged-wide.png");
						background-size: 100% 100%;
						content: "";
						height: 100%;
						position: absolute;
						left: 0;
						width: 100.6%;
						z-index: 0;
						top: 0;
					}

					@include mq(mobile) {
						margin: 0 0 20px;
					}


					img {
						display: block;
						width: 100%;
						height: auto;
					}
				}

				.sub-copy {
					h4 {
						font-family: $font-alt;
						font-size: $font-size-hero-small;
						margin: 0 0 15px;

						@include mq(mobile) {
							font-size: $font-size-large;
							margin: 0 0 10px;
						}
					}

					p {
						font-size: $font-size-med;
						line-height: $line-height-med;
						font-weight: $font-weight-light;

						@include mq(mobile) {
							font-size: $font-size-standard;
						}


						&:last-of-type {
							margin: 0;
						}
					}
				}
			}
		}
	}

	.section-team {
		padding: 70px 0 80px;
		background: $color-neutral;
		position: relative;

		@include mq(mobile) {
			padding: 60px 0;
		}


		svg.border-jagged-top {
			color: $color-neutral;
			width: 100%;
			position: absolute;
			top: -74px;
		}

		header {
			text-align: center;
			margin: 0 0 65px;

			@include mq(mobile) {
				margin: 0 0 40px;
			}


			h2 {
				font-size: $font-size-h1;
				font-family: $font-alt;
				margin: 0;

				@include mq(mobile) {
					font-size: $font-size-largest;
				}
			}
		}

		ul.list-team-member {
			@extend .list-default;

			display: flex;
			flex-wrap: wrap;

			@include mq(mobile) {
				flex-direction: column;
				align-items: center;
			}


			li {
				width: calc(25% - 11.25px);
				margin: 0 15px 60px 0;

				@include mq(mobile) {
					width: 100%;
					max-width: 290px;
					margin: 0 0 50px !important;
				}


				&:nth-child(4n) {
					margin: 0 0 60px 0;
				}

				.sub-image {
					margin: 0 0 30px;
					position: relative;

					&::before {
						background-image: url("../assets/images/border-jagged-team-member.png");
						background-size: 100% 100%;
						content: "";
						height: 100%;
						position: absolute;
						left: 0;
						width: 100%;
						z-index: 0;
						top: 0;
					}

					@include mq(mobile) {
						margin: 0 auto 25px;
						max-width: 195px;
					}


					img {
						width: 100%;
						height: auto;
						display: block;

						@include mq(mobile) {
							margin: 0 auto;
						}
					}
				}

				.sub-copy {
					max-width: 230px;

					@include mq(mobile) {
						max-width: none;
						text-align: center;
					}


					h4 {
						font-family: $font-alt;
						font-size: $font-size-large;
						margin: 0 0 15px;

						@include mq(mobile) {
							font-size: $font-size-largish;
							margin: 0 0 10px;
						}
					}

					h5 {
						font-size: $font-size-standard;
						font-weight: $font-weight-medium;
						margin: 0 0 15px;

						@include mq(mobile) {
							font-size: $font-size-med;
							margin: 0 0 5px;
						}
					}

					p {
						font-size: $font-size-med;
						line-height: $line-height-medi-large;
						color: $color-text-lightish;
						font-weight: $font-weight-light;

						&:last-of-type {
							margin: 0;
						}
					}
				}
			}
		}

		.sub-join {
			margin: 20px 0 0;
			text-align: center;

			@include mq(mobile) {
				margin: 0;
			}


			h3 {
				font-size: $font-size-h1;
				font-family: $font-alt;
				margin: 0 0 30px;

				@include mq(mobile) {
					font-size: $font-size-largest;
				}
			}

			.btn.btn-primary {
				font-size: $font-size-large;
				letter-spacing: 1px;

				@include mq(mobile) {
					padding: 11px 0;
				}
			}
		}
	}
}
