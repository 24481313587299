.footer-main {
	padding: 70px 0 50px;
	position: relative;

	svg.border-jagged-footer {
		position: absolute;
		top: -81px;
		color: $color-neutral;
		width: 100%;
		z-index: 0;

		@include mq(mobile) {
			top: -77px;
		}

		#grown-up-stuff &,
		#careers &,
		#grown-up-stuff-page &,
		#party-page &,
		#classes-clubs &,
		#error & {
			display: none;
		}
	}

	@include mq(mobile) {
		padding: 60px 0 45px;
	}

	.container {
		display: flex;
		justify-content: space-between;

		@include mq(mobile) {
			flex-direction: column;
		}
	}

	.sub-btn-mobile {
		display: none;
		width: 100%;
		padding: 10px 0;
		text-align: center;

		@include mq(mobile) {
			display: block;

			.btn.btn-primary {
				font-size: $font-size-larger;
				padding: 13px 0;
			}
		}
	}

	.sub-links {
		.newsletter-error {
			color: $color-error;
			display: block;
			font-size: $font-size-standard;

			&.mod-active {
				margin-top: 5px;
			}
		}
		.sub-newsletter {
			display: flex;
			align-items: center;
			margin: 0px;

			@include mq(mobile) {
				margin: 50px 0 35px;
				flex-direction: column;
			}

			h4 {
				align-self: center;
				font-family: $font-alt;
				font-size: $font-size-largest;
				margin: 0 20px 0 0;

				@include mq(mobile) {
					margin: 0 0 20px;
				}
			}

			.sub-thanks {
				display: none;

				h5 {
					margin: 0;
				}
			}

			form {
				display: flex;
				align-items: stretch;
				position: relative;

				@include mq(mobile) {
					width: 100%;
					box-sizing: border-box;
				}

				input[type="text"],
				select {
					border: 1px solid $color-borders-light;
					outline: none !important;
					min-width: 325px;
					padding: 0 17px;
					font-size: $font-size-largish;
					min-height: 43px;

					@include mq(mobile) {
						min-height: 40px;
						min-width: 0;
						width: 100%;
					}

					@include placeholder {
						color: $color-text-light;
					}
				}

				select {
					line-height: 43px;
				}

				.footer-name {
					display: none;
				}

				.form-name {
					display: none;
				}

				.form-opt-in {
					display: none;
					flex-direction: column;
					min-width: 325px;
					width: 100%;

					.wrap {
						display: flex;

						& > .row {
							flex: 1;

							& > .col-form-label {
								font-weight: $font-weight-semibold;
							}

							.mod-method {
								display: flex;

								.form-check {
									&:first-of-type {
										margin-right: 20px;
									}
								}
							}
						}
					}
				}

				.form-select {
					display: none;
					height: 100%;
					position: relative;

					@include mq(mobile) {
						min-width: 0;
						width: 75%;
					}

					select {
						appearance: none;
						border-radius: 0;
						background: $color-white;
						color: rgba($color-headings, 0.6);
						height: 100%;
						min-width: 265px;

						@include mq(mobile) {
							width: 100%;
						}
					}

					&:after {
						content: "";
						position: absolute;
						top: calc(50% - 3px);
						right: 10px;
						transform: translateY(-50%) rotate(135deg);
						border-style: solid;
						border-width: 1px 1px 0 0;
						display: inline-block;
						height: 8.5px;
						border-color: $color-primary;
						vertical-align: top;
						width: 8.5px;
					}
				}

				.btn.btn-primary,
				button {
					margin: 0;
					padding: 11px 15px;
					font-size: $font-size-large;
					font-family: $font-alt !important;

					@include mq(mobile) {
						padding: 8px 0;
						text-align: center;
						max-width: 45px;
					}

					&.mod-second,
					&.mod-third {
						display: none;
					}
				}

				input[type="submit"] {
					display: none;

					@include mq(mobile) {
						max-width: 105px !important;
					}
				}
			}
		}

		.sub-nav {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 50px;

			@include mq(mobile) {
				flex-direction: column-reverse;
				margin-top: 30px;

				.svg-icon {
					margin: 0 0 35px;
				}
			}

			ul.list-nav {
				@extend .list-default;

				display: flex;
				align-items: center;

				@include mq(mobile) {
					flex-wrap: wrap;
					justify-content: center;
				}

				li {
					color: $color-text-light;
					font-size: $font-size-smallish;

					@include mq(mobile) {
						margin: 0 0 15px;
					}

					&:first-child {
						font-weight: $font-weight-light;
						margin: 0 7px 0 0;

						@include mq(mobile) {
							width: 100%;
							text-align: center;
							margin: 0 0 15px;
						}
					}

					&:not(:first-child):not(:last-child):after {
						content: "";
						height: 9px;
						width: 1px;
						background: $color-text-light;
						margin: 0 10px;
						display: inline-block;
					}

					a {
						color: $color-text-light;

						&:hover {
							color: $color-primary;
						}
					}
				}
			}
		}
	}

	.sub-contact {
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		a.btn.btn-primary {
			display: block;
			text-align: center;
			padding: 12px 55px;

			@include mq(mobile) {
				display: none;
			}
		}

		.icon-payment-cards {
			img {
				display: block;

				@include mq(mobile) {
					margin: 10px auto 0;
					width: 100%;
					height: auto;
					max-width: 200px;
				}
			}
		}
	}
}
