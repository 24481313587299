.section-covid-modal {
	background-color: $color-white;
	left: 25%;
	padding: 50px;
	position: fixed;
	top: 25%;
	width: 50%;
	z-index: 99;

	&.mod-hidden {
		display: none;
	}

	h3 {
		font-weight: $font-weight-bold;
	}

	@include mq(mobile) {
		left: 2.5%;
		padding: 40px 20px 20px;
		top: 8%;
		width: 95%;

		h3 {
			font-size: $font-size-largish;
		}

		p {
			font-size: $font-size-small;
		}
	}


	.close-modal-button {
		background-color: $color-white;
		border: none;
		padding: 0;
		position: absolute;
		right: 3%;
		top: 9%;

		@include mq(mobile) {
			right: 4%;
			top: 2%;
		}


		.close-modal-icon {
			color: $color-headings;
			cursor: pointer;
			font-size: $font-size-largest;
			line-height: $line-height-base;
			text-transform: none;
		}

		.close-modal-icon:before {
			color: $color-black;
			content: "\2715";
			line-height: $line-height-reset;
			speak: none;
		}
	}
}

.sub-modal-background {
	background-color: rgba($color-black, 0.5);
	height: 100vh;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 98;

	&.mod-hidden {
		display: none;
	}
}
