#party-page {

	.section-hero {
		height: 200px;
		background-size: cover;
		background-position: center;
		display: flex;
		align-items: center;
		justify-content: center;

		@extend %hero-jagged-edge;

		@include mq(mobile) {
			height: 165px;
			padding: 0 5px;
			justify-content: flex-start;
			background-position: 77%;
		}

		h1 {
			color: $color-white;
			font-family: $font-alt;
			font-size: $font-size-hero-med-large;
			margin: 0;

			@include mq(mobile) {
				font-size: $font-size-h1;
				max-width: 45%;
				line-height: 1.2;
			}
		}

		.btn.btn-primary {
			position: absolute;
			bottom: -23.5px;
			left: 0;
			right: 0;
			margin: 0 auto;
			display: block;
			max-width: 295px;
			padding: 10px 0;
			text-align: center;
			font-size: $font-size-h3;
			z-index: 9;

			@include mq(mobile) {
				font-size: $font-size-large;
				max-width: 215px;
			}
		}
	}

	.section-intro {
		margin: 60px 0;

		@include mq(mobile) {
			margin: 50px 0 25px;
		}

		.container {
			max-width: 890px !important;

			@include mq(mobile) {
				max-width: none;
			}
		}

		.sub-main-intro {
			text-align: center;
			margin: 0 0 22px;

			@include mq(mobile) {
				text-align: left;
			}

			p {
				font-size: $font-size-largest;

				@include mq(mobile) {
					font-size: $font-size-largish;
				}

				&:last-of-type {
					margin: 0;
				}
			}
		}

		.sub-secondary-intro {
			margin: 0 auto 45px;
			max-width: 820px;
			text-align: center;

			@include mq(mobile) {
				text-align: left;
				max-width: none;
			}

			p {
				font-size: $font-size-med;
				line-height: $line-height-large;

				@include mq(mobile) {
					font-size: $font-size-standard;
				}

				&:last-of-type {
					margin: 0;
				}
			}
		}

		.sub-lists {
			max-width: 840px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;

			@include mq(mobile) {
				max-width: none;
				flex-direction: column;
				justify-content: flex-start;
			}

			ul {
				max-width: 400px;

				@include mq(mobile) {
					max-width: none;
				}

				li {
					font-size: $font-size-med;
					margin: 0 0 20px;

					@include mq(mobile) {
						font-size: $font-size-standard;
						margin: 0 0 10px;
					}
				}
			}
		}
	}

	.section-prices {
		text-align: center;
		margin: 0 0 90px;

		@include mq(mobile) {
			margin: 0 0 70px;
		}

		h2 {
			font-size: $font-size-h3;
			font-family: $font-alt;
			margin: 0 0 15px;

			@include mq(mobile) {
				font-size: $font-size-large;
			}
		}

		h3 {
			font-size: $font-size-h1;
			font-family: $font-alt;
			line-height: 1;
			margin: 0 0 5px;

			@include mq(mobile) {
				font-size: $font-size-larger;
			}
		}

		.btn {
			max-width: 295px;
			padding: 10px 0;
			text-align: center;
			display: block;
			margin: 40px auto 0;
			font-size: $font-size-h3;

			@include mq(mobile) {
				padding: 11px 0;
				max-width: none;
				font-size: $font-size-large;
				margin: 25px auto 0;
			}
		}
	}

	.section-generic-boxes svg.border-jagged-bottom {
		color: $color-white;
	}
}