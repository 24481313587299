/* COLOURS */
$color-black: #000000;
$color-white: #ffffff;

$color-body: $color-white;

$color-neutral: #F2ECDD;
$color-neutral-dark: #607D8B;
$color-neutral-light: #DCCDBE;

$color-borders: #aaaaaa;
$color-borders-light: #c8c8c8;

$color-text: #323232;
$color-text-lightish: #646464;
$color-text-light: #989898;

$color-headings: #263238;

$color-error: #ff0000;

$color-primary: #FE7320;
$color-secondary: #297422;
$color-tertiary: #006F91;

$color-primary-light: #FFB03B;
$color-secondary-light: #B2CB08;

$color-primary-pale: #FFD5A6;
$color-secondary-pale: #94B990;

$color-secondary-dark: #002300;
$color-secondary-medi-dark: #002C14;

$color-buttons: $color-primary;

$color-link: $color-primary;
$color-link-hover: lighten($color-link, 5%);
$color-link-active: $color-link-hover;

$color-validation-error: #cd0000;
$color-validation-success: #4eb73c;


$color-social-facebook: #3B598E;
$color-social-twitter: #00A2E3;
$color-social-linkedin: #0077AB;
$color-social-pinterest: #BF2228;

/* FONTS */
$font-standard: "proxima-nova", sans-serif;
$font-alt: 'kon-tiki-aloha-jf', sans-serif;

$font-base: 15;

$font-size-base: $font-base * 1px;
$font-size-standard: 15 / $font-base * 1rem;
$font-size-tiny: 12 / $font-base * 1rem;
$font-size-smallish: 13 / $font-base * 1rem;
$font-size-small: 14 / $font-base * 1rem;
$font-size-med: 16 / $font-base * 1rem;
$font-size-largish: 18 / $font-base * 1rem;
$font-size-large: 20 / $font-base * 1rem;
$font-size-larger: 22 / $font-base * 1rem;
$font-size-largest: 24 / $font-base * 1rem;

$font-size-h1: 34 / $font-base * 1rem;
$font-size-h2: 30 / $font-base * 1rem;
$font-size-h3: 26 / $font-base * 1rem;
$font-size-h4: $font-size-large;
$font-size-h5: $font-size-med;
$font-size-h6: $font-size-standard;

$font-size-hero-standard: $font-size-larger;
$font-size-hero-small: 28 / $font-base * 1rem;
$font-size-hero-med: 42 / $font-base * 1rem;
$font-size-hero-med-largish: 60 / $font-base * 1rem;
$font-size-hero-med-large: 66 / $font-base * 1rem;
$font-size-hero-large: 82 / $font-base * 1rem;
$font-size-hero-larger: 105 / $font-base * 1rem;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$headings-font: $font-standard;
$headings-font-weight: $font-weight-light;
$headings-color: $color-headings;
$headings-line-height: 1.4;

$line-height-reset: 1;
$line-height-base: 1.55;
$line-height-med: 1.75;
$line-height-medi-large: 1.9;
$line-height-large: 2;
$line-height-larger: 2.5;

$radius-button: 0;
$padding-button: 10px 40px;
$text-size-button: $font-size-largish;

/* STRUCTURE */
$viewport-width: 100%;
$container-width: 90%;
$container-max-width: 1080px;
$container-small-max-width: 900px;
$container-large-max-width: 1300px;

/* MOBILE STRUCTURE */
$container-width-mobile: 100%;
$container-pad-mobile: 10px;

/* MEDIA QUERY */
$mq-desktop-size: $container-max-width;
$mq-tablet-size: 1151px;
$mq-mobile-size: 911px;