#news-post,
#events-post {
	.section-hero {
		padding: 55px 0;

		@include mq(mobile) {
			padding: 0 0 15px;
		}


		.container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			position: relative;

			@include mq(mobile) {
				flex-wrap: wrap;
				padding: 0;
				flex-direction: column-reverse;
			}


			.sub-copy {
				max-width: 430px;

				@include mq(mobile) {
					width: 100%;
					max-width: none;
					padding: 55px 5px 0;
					position: relative;
				}


				ul.list-details {
					@extend .list-default;

					display: flex;
					align-items: center;
					margin: 0 0 25px;

					@include mq(mobile) {
						display: none;
					}


					li {
						font-size: $font-size-tiny;
						font-weight: $font-weight-semibold;
						text-transform: uppercase;

						&:first-child:after {
							content: '';
							display: inline-block;
							margin: 0 10px;
							width: 1px;
							height: 8.5px;
							background: $color-text-light;
						}
					}
				}

				h3 {
					color: $color-text;
					font-size: $font-size-standard;
					margin: 0 0 30px;

					@include mq(mobile) {
						display: none;
					}
				}

				h1 {
					margin: 0 0 25px;
					font-size: $font-size-hero-small;
					font-family: $font-alt;
					max-width: 345px;

					@include mq(mobile) {
						font-size: $font-size-larger;
						margin: 0 0 15px;
						max-width: none;
					}
				}

				p {
					font-size: $font-size-largish;
					line-height: $line-height-med;
					margin: 0;

					@include mq(mobile) {
						font-size: $font-size-med;
					}
				}

				.sub-social {
					position: absolute;
					bottom: 0;
					left: 0;
					cursor: pointer;

					@include mq(mobile) {
						bottom: inherit;
						top: 0;
						right: 0;
						margin: 0 auto;
						display: flex;
						justify-content: center;
						z-index: 2;
					}


					.sub-date-mob {
						display: none;

						h5 {
							margin: 0 20px 0 0;
							font-size: $font-size-med;
						}

						@include mq(mobile) {
							display: inline-block;
						}
					}

					ul.list-social {
						@extend .list-default;

						width: 45px;
						overflow: hidden;
						transition: all 0.3s ease;
						display: flex;
						align-items: center;

						@include mq(mobile) {
							padding: 0;
						}


						.wrap-svg-social {
							padding: 10px;
							background: $color-primary;
							border-radius: 50%;

							@include mq(mobile) {
								padding: 0;
							}


							.svg-icon {
								color: $color-white;

								&:hover {
									color: $color-white;
								}
							}
						}

						&:hover,
						&:focus {
							width: 100%;

							@include mq(mobile) {
								li {
									@include splitLeftRight;

									&:not(.mod-event) {
										transform: rotate(0deg);
										opacity: 1;
									}
								}
							}
						}

						li {
							margin: 0 22px 0 0;
							padding: 0;
							display: inline-block;

							@include mq(mobile) {
								margin: 0;
								position: absolute;
								height: 45px;
								width: 45px;
								background: $color-primary;
								border-radius: 50%;
								display: flex;
								align-items: center;
								justify-content: center;
								left: 0;
								right: 0;
								margin: 0 auto;
								transition: 0.4s all;

								&:not(.mod-event) {
									transform: rotate(180deg);
									opacity: 0;
								}

								&.mod-event {
									z-index: 2;
								}

								&.mod-facebook {
									background: $color-social-facebook;
								}

								&.mod-linkedin {
									background: $color-social-linkedin;
								}

								&.mod-twitter {
									background: $color-social-twitter;
								}

								&.mod-pinterest {
									background: $color-social-pinterest;
								}
							}


							.svg-icon {
								transition: color 0.2s ease;
								color: $color-borders;

								@include mq(mobile) {
									color: $color-white;
								}


								&:hover {
									color: $color-primary;
								}
							}
						}
					}
				}
			}

			.sub-image {
				height: 320px;
				width: 550px;
				margin: 0 0 0 auto;

				@include mq(mobile) {
					width: 100%;
					height: 208px;
					margin: 0;
				}


				ul.list-image {
					@extend .list-default;

					height: 320px;

					@include mq(mobile) {
						height: 208px;
					}


					li.item-image {
						margin: 0;
						height: 320px;

						@include mq(mobile) {
							height: 208px;
						}


						.sub-background {
							height: 320px;
							background-position: center;
							background-size: cover;

							@include mq(mobile) {
								height: 208px;
							}
						}
					}
				}
			}
		}
	}

	.section-post {
		padding: 0 0 70px;

		@include mq(mobile) {
			padding: 0 0 60px;
		}


		.sub-content {
			.sub-copy {
				max-width: 785px;

				p {
					font-size: $font-size-med;
					line-height: $line-height-larger;
					font-weight: $font-weight-light;
					color: $color-text-lightish;

					@include mq(mobile) {
						font-size: $font-size-standard;
						line-height: $line-height-med;
					}
				}
			}
		}
	}

	.section-related.post-page {
		padding: 60px 0;
		background-color: $color-neutral;
		position: relative;

		svg.border-jagged-top {
			color: $color-neutral;
			position: absolute;
			width: 100%;
			top: -76px;
		}

		@include mq(mobile) {
			padding: 60px 0 80px;
		}


		h2 {
			margin: 0 0 55px;
			font-size: $font-size-h2;
			font-family: $font-alt;
			text-align: center;

			@include mq(mobile) {
				margin: 0 0 40px;
			}
		}
	}
}
