#contact {
	.section-hero {
		background-size: cover;
		background-position: center;
		padding: 60px 0;

		@extend %hero-jagged-edge;

		&.mod-mobile {
			display: none;
		}

		@include mq(mobile) {
			padding: 55px 0;
			display: none;

			&.mod-mobile {
				display: flex;
			}
		}

		h1 {
			font-family: $font-alt;
			text-align: center;
			font-size: $font-size-hero-med-large;
			color: $color-white;
			margin: 0 0 15px;

			@include mq(mobile) {
				margin: 0;
				font-size: $font-size-h1;
			}
		}

		.sub-details {
			max-width: 880px;
			margin: 0 auto;

			@include mq(mobile) {
				display: none;
			}


			ul.list-detail {
				@extend .list-default;

				display: flex;
				justify-content: space-between;
				align-items: center;

				&:before {
					content: '';
					display: block;
					width: 3px;
					flex: 0 1 auto;
					height: 110px;
					background: rgba($color-white, 0.25);
				}

				li.item-detail {
					text-align: center;
					flex-grow: 1;

					&:first-child {
						order: -1;
					}

					&:last-child {
						flex-grow: 0;
						margin: 0 0 0 40px;
					}

					h3 {
						font-family: $font-alt;
						color: $color-secondary-light;
						font-size: $font-size-hero-med;
						margin: 0 0 10px;
					}

					h4 {
						font-family: $font-alt;
						color: $color-white;
						font-size: $font-size-largish;
						letter-spacing: 1px;
						margin: 0 0 5px;

						&:last-of-type {
							margin: 0;
						}

						a {
							&[href^="tel:"] {
								color: $color-white;
							}
						}
					}
				}
			}
		}
	}

	.section-mobile-information {
		background: $color-neutral;
		padding: 40px 0 45px;
		display: none;

		@include mq(mobile) {
			display: block;
		}


		ul.list-detail {
			@extend .list-default;

			text-align: center;

			li {
				&:first-child {
					margin: 0 0 30px;
				}

				h3 {
					font-size: $font-size-h3;
					font-family: $font-alt;
					color: $color-secondary;
					margin: 0 0 5px;
				}

				h4 {
					font-family: $font-alt;
					color: $color-text;
					font-size: $font-size-largish;
					margin: 0 0 5px;

					&:last-of-type {
						margin: 0;
					}

					a {
						&[href^="tel:"] {
							color: $color-text;
						}
					}
				}
			}
		}
	}

	.section-form {
		text-align: center;
		margin: 60px 0 80px;

		@include mq(mobile) {
			margin: 45px 0 50px;
		}


		form {
			max-width: 700px !important;

			header {
				margin: 0 0 30px;

				@include mq(mobile) {
					margin: 0 0 30px;
				}


				p {
					font-size: $font-size-largest;

					@include mq(mobile) {
						font-size: $font-size-largish;
					}
				}

				h2 {
					font-size: $font-size-h3;
					font-family: $font-alt;
					margin: 40px 0 0;

					@include mq(mobile) {
						margin: 50px 0 0;
						font-size: $font-size-large;
					}
				}
			}
		}
	}

	.section-map {
		position: relative;

		.sub-locations {
			position: absolute;
			top: 40px;
			left: 0;
			right: 0;
			margin: 0 auto;
			max-width: 780px;
			z-index: 3;

			@include mq(mobile) {
				position: relative;
				top: 40px;
				max-width: 90%;
			}


			ul.list-location {
				@extend .list-default;

				display: flex;
				justify-content: space-between;

				@include mq(mobile) {
					flex-direction: column;
					justify-content: flex-start;
				}


				li {
					width: calc(50% - 7.5px);
					background: $color-white;
					padding: 25px 32px 20px;
					text-align: center;
					box-shadow: 0 0 16px 0 rgba($color-black, 0.25);

					@include mq(mobile) {
						padding: 15px 55px;
						width: 100%;

						&:first-child {
							margin: 0 0 15px;
						}
					}


					h4 {
						font-size: $font-size-largish;
						margin: 0 0 10px;
						font-weight: $font-weight-bold;

						@include mq(mobile) {
							margin: 0 0 5px;
							font-size: $font-size-med;
						}
					}

					h5 {
						margin: 0;
						font-weight: $font-weight-light;
						font-size: $font-size-med;

						@include mq(mobile) {
							font-size: $font-size-small;
							line-height: $line-height-medi-large;
						}
					}

					a {
						margin: 10px 0 0;
						display: inline-block;
					}
				}
			}
		}

		#map {
			height: 560px;

			@include mq(mobile) {
				height: 410px;
			}
		}
	}
}
