#sitemap {
	.cont-background {
		display: block;
	}

	.section-site-map .container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 60px 0;

		> div {
			min-width: 48%;
		}

		h1 {
			margin: 0 0 40px 0;
			text-align: center;
			text-transform: uppercase;
			width: 100%;
		}

		h2 {
			margin: 20px 0 10px 0;
			text-transform: uppercase;
			font-size: $font-size-med;
		}

		ol,
		ul {
			margin: 0;
			padding: 0;

			li {
				margin: 0 0 3px 18px;
				padding: 0;
			}
		}
	}
}
