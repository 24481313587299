%hero-jagged-edge {
	position: relative;

	svg.border-jagged-top {
		position: absolute;
		bottom: -75px;
		color: $color-white;
		width: 100%;
		left: 0;

		@include mq(mobile) {
			bottom: -76px;
		}
	}
}